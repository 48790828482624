@font-face {
	font-family: "MuseoModerno Light";
	src: url("./fonts/MuseoModerno-Light.eot");
	src: url("./fonts/MuseoModerno-Light.eot?#iefix")
			format("embedded-opentype"),
		url("./fonts/MuseoModerno-Light.woff2") format("woff2"),
		url("./fonts/MuseoModerno-Light.woff") format("woff"),
		url("./fonts/MuseoModerno-Light.ttf") format("truetype"),
		url("./fonts/MuseoModerno-Light.svg#MuseoModerno-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "MuseoModerno";
	src: url("./fonts/MuseoModerno-Regular.eot");
	src: url("./fonts/MuseoModerno-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("./fonts/MuseoModerno-Regular.woff2") format("woff2"),
		url("./fonts/MuseoModerno-Regular.woff") format("woff"),
		url("./fonts/MuseoModerno-Regular.ttf") format("truetype"),
		url("./fonts/MuseoModerno-Regular.svg#MuseoModerno-Regular")
			format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Nunito Sans";
	src: url("./fonts/NunitoSans-Bold.eot");
	src: url("./fonts/NunitoSans-Bold.eot?#iefix") format("embedded-opentype"),
		url("./fonts/NunitoSans-Bold.woff2") format("woff2"),
		url("./fonts/NunitoSans-Bold.woff") format("woff"),
		url("./fonts/NunitoSans-Bold.ttf") format("truetype"),
		url("./fonts/NunitoSans-Bold.svg#NunitoSans-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/* ************************************************************************************************************************************************ */

@media screen and (max-width: 999px) and (min-height: 640px) {
	body {
		background: linear-gradient(
			294.61deg,
			#313645 14.41%,
			#4d5757 34.9%,
			#6b7b6b 57.57%,
			#526f62 91.87%
		);
		box-shadow: 0 28px 16px rgba(0, 0, 0, 0.15);
		min-height: 100vh;
	}

	.App {
		display: flex;
		min-height: calc(100vh - 75px);
		padding-top: 25px;
		padding-bottom: 25px;
		flex-wrap: wrap;
		justify-content: center;
	}

	.Contents {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: space-between;
		width: calc(100% - 50px);
		max-width: 1200px;
		align-items: center;
		row-gap: 10px;
	}

	.Header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.HeaderBotones {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 15px;
	}

	.Connect {
		display: flex;
		height: 45px;
		padding: 5px;
		position: relative;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		align-items: center;
		letter-spacing: 1px;
		color: #415856;
	}

	.addTokenButton {
		display: flex;
		cursor: pointer;
		text-align: center;
		padding: 4px;
		position: relative;
		background: #e6007e;
		border: 2px solid #e6007e;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		column-gap: 10px;
		align-items: center;
		letter-spacing: 2px;
		color: #ffffff;
	}

	.addTokenButtonMobile {
		display: flex;
		cursor: pointer;
		padding: 5px;
		max-width: fit-content;
		column-gap: 10px;
		position: relative;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		align-items: center;
		letter-spacing: 1px;
		color: #415856;
		align-self: center;
	}

	.tokenButtonImg {
		height: 33px;
		width: 33px;
	}

	.actionsApp {
		display: none;
	}

	.MenuMovilApp {
		cursor: pointer;
		display: flex;
		height: 45px;
		width: 45px;
		background-color: #ffffff;
		border-radius: 17.5px;
		align-items: center;
		justify-content: center;
	}

	.menuApp {
		cursor: default;
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0px;
		right: 0px;
		background-color: #ffffff;
		padding: 5px;
		border-radius: 20px;
		border: 2.5px solid #e6007e;
		row-gap: 25px;
		z-index: 2;
	}

	.menuCerrarApp {
		cursor: pointer;
	}

	.headerMenuApp {
		display: flex;
		justify-content: flex-end;
	}

	.ActionList {
		display: flex;
		position: relative;
		row-gap: 5px;
		width: fit-content;
		flex-direction: column;
		justify-content: space-around;
	}

	.li {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		position: relative;

		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #415856;
		padding-left: 15px;
		padding-right: 15px;
	}

	.enlaces {
		display: flex;
		text-decoration: none;
		color: #415856;
		column-gap: 25px;
		align-items: center;
	}

	.Screen {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-width: 350px;
		max-width: 750px;
		align-self: center;
	}

	.ScreenHeader {
		position: relative;
		width: 100%;
		height: 32px;
		justify-content: flex-end;
		background: #57a650;

		border-top-right-radius: 10px;
		border-top-left-radius: 10px;

		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.elipses {
		display: flex;
		flex-direction: row;
		padding: 10px;
	}
	.elipse1,
	.elipse2,
	.elipse3 {
		margin-left: 5px;
	}

	.Block {
		display: flex;
		flex-direction: column;
		height: 100%;
		background-color: #ffffff;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		justify-content: center;
	}

	.BackgroundApp {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-around;
		padding: 25px;
		row-gap: 15px;
	}

	.Input,
	.Output {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		flex-direction: row;
		position: relative;
		min-height: 35px;
		background: #ffffff;
		border: 2.5px solid #d8d8d8;
		border-radius: 20px;
	}

	.loadingIcon {
		position: relative;
		width: 25px;
		height: 25px;
		margin-left: -55px;
		margin-right: 5px;
	}

	.botonSwitch {
		position: relative;
		width: 45px;
		height: 45px;
		display: block;
		margin: 0 auto;
		z-index: 1;
		margin-top: -25px;
		margin-bottom: -25px;
	}

	.Selection {
		display: flex;
		position: relative;
		margin-right: 10px;
	}

	.selection {
		display: flex;
		position: relative;
		align-items: center;
	}

	.tokenText1,
	.tokenText2 {
		display: none;
	}

	.gwhfToken,
	.usdcToken {
		padding-top: 5px;
		padding-bottom: 5px;
		max-width: 75px;
	}

	.Price {
		position: relative;
		font-family: "MuseoModerno Light";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		text-align: center;
	}

	.textarea,
	.textarea2 {
		display: flex;
		width: 100%;
		padding-right: 25px;
		padding-left: 25px;
		position: relative;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 300;
		font-size: 35px;
		align-items: center;
		justify-content: center;
		border: 0;
		color: #262626;
		outline: none;
		background-color: #ffffff;
		border-radius: 20px;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.Cartera {
		display: flex;
		position: relative;
		width: 100%;
		align-items: center;
		justify-content: stretch;
	}

	.cartera {
		display: flex;
		position: relative;
		width: 100%;
		justify-content: center;
		position: relative;
		padding-top: 5px;
		padding-bottom: 5px;
		border: 0;
		font-family: "MuseoModerno Light";
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		color: #ffffff;
		background: #e6007e;
		border-radius: 20px;
		cursor: pointer;
	}

	.cartera:disabled {
		cursor: not-allowed;
		background: #ac5585;
	}

	.Pet {
		position: relative;
		background: url("../public/Imagenes/gatoCirculo.png");
		background-size: 100%;
		width: 150px;
		height: 150px;
		left: 0;
		margin: 0 auto;
		top: 8px;
	}

	.gato {
		display: none;
	}

	.loadingIconModal {
		display: block;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 50px;
	}

	.modalOverlay {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 3;
	}

	.modalContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 300px;
		height: 250px;
		background: #262626;
		position: relative;
		border-radius: 25px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		padding: 25px;
		border: 1px solid #ccc;
		row-gap: 35px;
	}

	.modalHeader {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-weight: 500;
		font-size: 20px;
		color: #fff;
		row-gap: 10px;
	}

	.modalTitle {
		font-weight: 500;
		font-size: 24px;
		color: #ccc;
	}

	.modalTop {
		display: flex;
		flex-direction: column;

		font-size: 18px;
		color: #ccc;
		font-weight: 400;
	}

	.modalContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 25px;
		text-align: center;
	}

	.modalBottom {
		display: flex;
		flex-direction: row;
		column-gap: 25px;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 45px;
		color: #fff;
		letter-spacing: 2px;
	}

	.modalButtonDiv {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.closeButton {
		display: flex;
		align-content: center;
		justify-content: center;
		column-gap: 25px;
		flex-wrap: wrap;
		font-size: 16px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		width: fit-content;
		border-radius: 2px;
		background-color: #52a6ff;
		padding: 7.5px;
		border-radius: 15px;
		margin: auto;
		cursor: pointer;
	}

	.modalButton {
		display: flex;
		align-content: center;
		justify-content: center;
		column-gap: 25px;
		flex-wrap: wrap;
		font-size: 16px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		width: fit-content;
		border-radius: 2px;
		background-color: #e6007e;
		padding: 7.5px;
		border-radius: 15px;
		cursor: pointer;
	}

	.WithdrawNotice {
		display: flex;
		align-items: center;
		background-color: #e6007e;
		padding: 5px;
		padding-left: 25px;
		padding-right: 25px;
		border-radius: 15px;
		column-gap: 15px;
	}

	.WithdrawText {
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 400;
		color: #ffffff;
	}

	.WithdrawBoolText {
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		padding: 5px;
		border-radius: 15px;
	}

	.InfoIcon {
		width: 25px;
	}

	.WithdrawStateMessage {
		display: flex;
		position: absolute;
		z-index: 2;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #415856;
		background-color: #f0e400;
		border: 2px solid #ffffff;
		text-align: center;
		border-radius: 15px;
		padding: 10px;
		width: 325px;
		margin: 47px 0 0 0;
	}

	.Dashboard {
		display: flex;
		height: 100%;
		justify-content: center;
	}

	.growi-dashboard {
		display: flex;
		flex-direction: column;
		padding: 15px;
		padding-top: 25px;
		padding-bottom: 0px;
		row-gap: 25px;
		width: 100%;
		max-width: 1200px;
		border-radius: 25px;
	}
}

@media screen and (max-width: 575px) and (min-height: 331px) {
	.menuApp {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0px;
		right: 0px;
		background-color: #ffffff;
		padding: 10px;
		border-radius: 20px;
		border: 2.5px solid #e6007e;
		row-gap: 25px;
		z-index: 2;
	}
}

@media screen and (max-width: 575px) {
	.addTokenButton {
		display: none;
	}
}

@media screen and (max-height: 450px) {
	.App {
		display: flex;
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 10px;
		padding-top: 10px;
		height: calc(100vh - 20px);
	}

	.Input,
	.Output {
		display: flex;
		position: relative;
		height: 40px;
		width: 100%;
		align-items: center;
		flex-direction: row;
		background: #ffffff;
		border: 2.5px solid #d8d8d8;
		border-radius: 20px;
		padding-top: 2px;
		padding-bottom: 2px;
	}

	.gwhfToken,
	.usdcToken {
		max-height: 40px;
	}

	.Block {
		display: flex;
		flex-direction: column;
		height: 100%;
		max-height: 225px;
		background-color: #ffffff;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		justify-content: center;
	}

	.BackgroundApp {
		display: flex;
		height: 100%;
		flex-direction: column;
		padding: 15px;
		row-gap: 5px;
		justify-content: space-around;
	}

	.Contents {
		display: flex;
		flex-direction: column;
		position: relative;
		row-gap: 5px;
		width: 100%;
		height: 100%;
	}

	.Header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.HeaderBotones {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 15px;
	}

	.growiHfIcon {
		height: 45px;
	}

	.WithdrawStateMessage {
		display: flex;
		position: absolute;
		z-index: 2;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #415856;
		background-color: #f0e400;
		border: 2px solid #ffffff;
		text-align: center;
		border-radius: 15px;
		padding: 10px;
		margin: -125px 0 0 0;
	}
}

@media screen and (max-height: 330px) {
	.menuApp {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0px;
		right: 0px;
		background-color: #ffffff;
		padding: 10px;
		border-radius: 20px;
		border: 2.5px solid #e6007e;
		row-gap: 10px;
		z-index: 2;
	}

	.menuCerrarApp {
		cursor: pointer;
		height: 20px;
	}

	.addTokenButtonMobile {
		display: flex;
		cursor: pointer;
		padding: 5px;
		max-width: fit-content;
		column-gap: 10px;
		position: relative;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		align-items: center;
		letter-spacing: 1px;
		color: #415856;
		align-self: center;
	}
}

@media screen and (min-height: 330px) and (max-height: 450px) {
	.addTokenButtonMobile {
		display: flex;
		cursor: pointer;
		padding: 5px;
		max-width: fit-content;
		column-gap: 10px;
		position: relative;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		align-items: center;
		letter-spacing: 1px;
		color: #415856;
		align-self: center;
	}
}

@media screen and (max-height: 640px) and (max-width: 575px) {
	.addTokenButtonMobile {
		display: flex;
		cursor: pointer;
		padding: 5px;
		max-width: fit-content;
		column-gap: 10px;
		position: relative;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		align-items: center;
		letter-spacing: 1px;
		color: #415856;
		align-self: center;
	}
}

@media screen and (max-height: 640px) and (min-height: 451px) {
	.App {
		display: flex;
		padding: 25px;
		height: calc(100vh - 50px);
	}

	.gwhfToken,
	.usdcToken {
		max-height: 70px;
	}

	.Block {
		display: flex;
		flex-direction: column;
		height: 100%;
		max-height: 300px;
		background-color: #ffffff;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		justify-content: center;
	}

	.BackgroundApp {
		display: flex;
		height: 100%;
		flex-direction: column;
		padding: 25px;
		row-gap: 15px;
		justify-content: space-around;
	}

	.Contents {
		display: flex;
		flex-direction: column;
		position: relative;
		row-gap: 25px;
		width: 100%;
		height: 100%;
	}

	.Header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.HeaderBotones {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 15px;
	}

	.addTokenButton {
		display: none;
	}

	.addTokenButtonMobile {
		display: flex;
		cursor: pointer;
		padding: 5px;
		max-width: fit-content;
		column-gap: 10px;
		position: relative;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		align-items: center;
		letter-spacing: 1px;
		color: #415856;
		align-self: center;
	}

	.WithdrawStateMessage {
		display: flex;
		position: absolute;
		z-index: 2;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #415856;
		background-color: #f0e400;
		border: 2px solid #ffffff;
		text-align: center;
		border-radius: 15px;
		padding: 10px;
		width: 300px;
		margin: -125px 0 0 0px;
	}
}

@media screen and (max-height: 640px) {
	body {
		background: linear-gradient(
			294.61deg,
			#313645 14.41%,
			#4d5757 34.9%,
			#6b7b6b 57.57%,
			#526f62 91.87%
		);
		box-shadow: 0 28px 16px rgba(0, 0, 0, 0.15);
	}

	.Connect {
		padding: 5px;
		position: relative;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		display: flex;
		align-items: center;
		letter-spacing: 1px;
		color: #415856;
		height: 38px;
	}

	.tokenButtonImg {
		height: 30px;
		width: 30px;
	}

	.actionsApp {
		display: none;
	}

	.MenuMovilApp {
		cursor: pointer;
		display: flex;
		height: 38px;
		width: 38px;
		background-color: #ffffff;
		border-radius: 10px;
		align-items: center;
		justify-content: center;
	}

	.menuCerrarApp {
		cursor: pointer;
	}

	.headerMenuApp {
		display: flex;
		justify-content: flex-end;
	}

	.ActionList {
		display: flex;
		position: relative;
		row-gap: 5px;
		width: fit-content;
		flex-direction: column;
		justify-content: space-around;
	}

	.li {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		position: relative;

		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #415856;
		padding-left: 15px;
		padding-right: 15px;
	}

	.enlaces {
		display: flex;
		text-decoration: none;
		color: #415856;
		column-gap: 25px;
		align-items: center;
	}

	.Screen {
		display: flex;
		flex-direction: column;
		max-width: 900px;
		height: 100%;
		width: 100%;
		align-self: center;
		justify-content: center;
	}

	.ScreenHeader {
		position: relative;
		width: 100%;
		height: 32px;
		justify-content: flex-end;
		background: #57a650;

		border-top-right-radius: 10px;
		border-top-left-radius: 10px;

		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.elipses {
		display: flex;
		flex-direction: row;
		padding: 10px;
	}
	.elipse1,
	.elipse2,
	.elipse3 {
		margin-left: 5px;
	}

	.Input,
	.Output {
		display: flex;
		position: relative;
		width: 100%;
		align-items: center;
		flex-direction: row;
		background: #ffffff;
		border: 2.5px solid #d8d8d8;
		border-radius: 20px;
		padding-top: 2px;
		padding-bottom: 2px;
	}

	.loadingIcon {
		position: relative;
		width: 25px;
		height: 25px;
		margin-left: -55px;
		margin-right: 5px;
	}

	.botonSwitch {
		display: flex;
		position: relative;
		max-height: 50px;
		z-index: 1;
		margin-top: -25px;
		margin-bottom: -25px;
	}

	.Selection {
		display: flex;
		position: relative;
		margin-right: 10px;
		padding-top: 2.5px;
		padding-bottom: 2.5px;
	}

	.selection {
		display: flex;
		position: relative;
		align-items: center;
		height: 100%;
	}

	.tokenText1,
	.tokenText2 {
		display: none;
	}

	.Price {
		position: relative;
		font-family: "MuseoModerno Light";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		text-align: center;
	}

	.textarea,
	.textarea2 {
		display: flex;
		width: 100%;
		padding-right: 25px;
		padding-left: 25px;
		position: relative;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 300;
		font-size: 25px;
		align-items: center;
		justify-content: center;
		border: 0;
		color: #262626;
		outline: none;
		background-color: #ffffff;
		border-radius: 20px;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.Cartera {
		display: flex;
		position: relative;
		width: 100%;
		align-items: center;
		justify-content: stretch;
	}

	.cartera {
		display: flex;
		position: relative;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		position: relative;
		padding-top: 5px;
		padding-bottom: 5px;
		border: 0;
		font-family: "MuseoModerno Light";
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		color: #ffffff;
		background: #e6007e;
		border-radius: 20px;
		cursor: pointer;
	}

	.cartera:disabled {
		cursor: not-allowed;
		background: #ac5585;
	}

	.Pet {
		display: none;
	}

	.gato {
		display: none;
	}

	.loadingIconModal {
		display: block;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 50px;
	}

	.modalOverlay {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 3;
	}

	.modalContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 350px;
		height: 250px;
		background: #262626;
		position: relative;
		border-radius: 25px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		padding: 25px;
		border: 1px solid #ccc;
		row-gap: 35px;
	}

	.modalHeader {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-weight: 500;
		font-size: 20px;
		color: #fff;
		row-gap: 10px;
	}

	.modalTitle {
		font-weight: 500;
		font-size: 24px;
		color: #ccc;
	}

	.modalTop {
		display: flex;
		flex-direction: column;

		font-size: 18px;
		color: #ccc;
		font-weight: 400;
	}

	.modalContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 25px;
		text-align: center;
	}

	.modalBottom {
		display: flex;
		flex-direction: row;
		column-gap: 25px;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 45px;
		color: #fff;
		letter-spacing: 2px;
	}

	.modalButtonDiv {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.closeButton {
		display: flex;
		align-content: center;
		justify-content: center;
		column-gap: 25px;
		flex-wrap: wrap;
		font-size: 16px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		width: fit-content;
		border-radius: 2px;
		background-color: #52a6ff;
		padding: 7.5px;
		border-radius: 15px;
		margin: auto;
		cursor: pointer;
	}

	.modalButton {
		display: flex;
		align-content: center;
		justify-content: center;
		column-gap: 25px;
		flex-wrap: wrap;
		font-size: 16px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		width: fit-content;
		border-radius: 2px;
		background-color: #e6007e;
		padding: 7.5px;
		border-radius: 15px;
		cursor: pointer;
	}

	.WithdrawNotice {
		display: flex;
		width: fit-content;
		align-items: center;
		background-color: #e6007e;
		padding: 5px;
		padding-left: 25px;
		padding-right: 25px;
		border-radius: 15px;
		column-gap: 15px;
	}

	.WithdrawText {
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 400;
		color: #ffffff;
	}

	.WithdrawBoolText {
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		padding: 5px;
		border-radius: 15px;
	}

	.InfoIcon {
		width: 25px;
	}

	.Dashboard {
		display: flex;
		height: 100%;
		justify-content: center;
	}

	.growi-dashboard {
		display: flex;
		flex-direction: column;
		padding: 15px;
		padding-top: 25px;
		padding-bottom: 0px;
		row-gap: 25px;
		width: 100%;
		max-width: 1200px;
		border-radius: 25px;
	}
}

@media screen and (min-width: 576px) {
	.menuApp {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0px;
		right: 0px;
		background-color: #ffffff;
		padding: 10px;
		border-radius: 20px;
		border: 2.5px solid #e6007e;
		row-gap: 25px;
		z-index: 2;
	}

	.addTokenButtonMobile {
		display: none;
	}

	.addTokenButton {
		display: flex;
		cursor: pointer;
		text-align: center;
		padding: 4px;
		position: relative;
		background: #e6007e;
		border: 2px solid #e6007e;
		border-radius: 20px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		column-gap: 5px;
		align-items: center;
		letter-spacing: 2px;
		color: #ffffff;
	}
}

@media screen and (min-width: 1000px) and (min-height: 640px) {
	body {
		background: #b99275;
	}

	.App {
		display: flex;
		flex-direction: column;
		padding-top: 50px;
		padding-bottom: 50px;
		row-gap: 50px;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	.titulo {
		font-size: 20px;
		font-weight: 600;
		font-family: "MuseoModerno";
		color: #262626;
		width: 253px;
		height: 48px;
		position: absolute;
		margin-top: 0;
		display: flex;
		align-items: center;
	}

	.lista {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
		gap: 8px;
		width: 253px;
		height: 150px;
		left: 294px;
		top: 64.5px;
		font-family: "MuseoModerno";
		font-style: normal;
		color: #262626;
		font-size: 16px;
		font-weight: 600;
		margin-top: 68px;
	}

	li {
		list-style: none;
	}

	.botonApp {
		display: flex;
		padding: 10px;
		gap: 10px;

		position: absolute;

		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 20px;

		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 25px;
		color: #415856;
		margin-top: 50px;
	}

	.Contents {
		display: flex;
		position: relative;
		flex-direction: column;
		row-gap: 50px;
		padding: 75px;
		padding-top: 25px;
		padding-bottom: 0px;
		margin-right: 75px;
		margin-left: 75px;
		background: linear-gradient(
			294.61deg,
			#313645 14.41%,
			#4d5757 34.9%,
			#6b7b6b 57.57%,
			#526f62 91.87%
		);
		box-shadow: 0 28px 16px rgba(0, 0, 0, 0.15);
		border-radius: 20px;
		max-width: 1200px;
	}

	.Header {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.HeaderBotones {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		column-gap: 20px;
	}

	.Connect {
		display: flex;
		position: relative;
		padding: 8px;
		height: 52px;
		background: #f0e400;
		border: 2px solid #f0e400;
		border-radius: 25px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		align-items: center;
		letter-spacing: 2px;
		color: #415856;
	}

	.addTokenButton {
		display: flex;
		cursor: pointer;
		text-align: center;
		padding: 4px;
		position: relative;
		background: #e6007e;
		border: 2px solid #e6007e;
		border-radius: 25px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		column-gap: 10px;
		align-items: center;
		letter-spacing: 2px;
		color: #ffffff;
	}

	.tokenButtonImg {
		height: 40px;
		width: 40px;
	}

	.actionsApp {
		cursor: pointer;
		width: 52px;
		height: 52px;
	}

	.triangulo-redondeado,
	.triangulo-redondeado:before,
	.triangulo-redondeado:after {
		position: relative;
		display: block;
		width: 27px;
		height: 27px;
		background: #fff;
		border-top-right-radius: 13.5px;
		align-items: center;
	}
	.triangulo-redondeado {
		margin: 4px auto 0 auto;
		transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
	}
	.triangulo-redondeado:before,
	.triangulo-redondeado:after {
		content: "";
		position: absolute;
	}
	.triangulo-redondeado:before {
		transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
			translate(0, -50%);
	}
	.triangulo-redondeado:after {
		transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
			translate(50%);
	}

	.loadingIconModal {
		display: block;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 50px;
	}

	.modalOverlay {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 3;
	}

	.modalContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 350px;
		height: 250px;
		background: #262626;
		position: relative;
		border-radius: 25px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		padding: 25px;
		border: 1px solid #ccc;
		row-gap: 35px;
	}

	.modalHeader {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-weight: 500;
		font-size: 20px;
		color: #fff;
		row-gap: 10px;
	}

	.modalTitle {
		font-weight: 500;
		font-size: 24px;
		color: #ccc;
	}

	.modalTop {
		display: flex;
		flex-direction: column;

		font-size: 18px;
		color: #ccc;
		font-weight: 400;
	}

	.modalContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 25px;
		text-align: center;
	}

	.modalBottom {
		display: flex;
		flex-direction: row;
		column-gap: 25px;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 45px;
		color: #fff;
		letter-spacing: 2px;
	}

	.modalButtonDiv {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.closeButton {
		display: flex;
		align-content: center;
		justify-content: center;
		column-gap: 25px;
		flex-wrap: wrap;
		font-size: 16px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		width: fit-content;
		border-radius: 2px;
		background-color: #52a6ff;
		padding: 7.5px;
		border-radius: 15px;
		margin: auto;
		cursor: pointer;
	}

	.modalButton {
		display: flex;
		align-content: center;
		justify-content: center;
		column-gap: 25px;
		flex-wrap: wrap;
		font-size: 16px;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		width: fit-content;
		border-radius: 2px;
		background-color: #e6007e;
		padding: 7.5px;
		border-radius: 15px;
		cursor: pointer;
	}

	.ActionList {
		display: flex;
		row-gap: 5px;
		width: fit-content;
		flex-direction: column;
		justify-content: space-around;
		background-color: white;
		border-radius: 20px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		position: relative;
		z-index: 2;
		padding: 15px;
		margin: -15px 0 0 -45px;
	}

	.enlaces {
		display: flex;
		text-decoration: none;
		color: #415856;
		column-gap: 15px;
	}

	.ContactList {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		background-color: white;
		width: 120px;
		height: 210px;
		margin-top: 135px;
		border-radius: 0 0 20px 20px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		position: absolute;
		z-index: 2;
	}

	.li {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		position: relative;

		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #415856;
	}

	.Screen {
		position: relative;
		width: 85%;
		background: #ffffff;
		border-radius: 10px;
		margin-right: 75px;
	}

	.ScreenHeader {
		position: relative;
		width: 100%;
		height: 32px;
		justify-content: flex-end;
		background: #57a650;

		border-top-right-radius: 10px;
		border-top-left-radius: 10px;

		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.elipse1,
	.elipse2 {
		margin-right: 5px;
	}
	.elipse3 {
		margin-right: 25px;
	}

	.Block {
		display: flex;
		position: relative;
		padding: 30px;
		justify-content: center;
	}

	.BackgroundApp {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		position: relative;
		background: #f5f5f5;
		border-radius: 10px;
		padding: 50px;
		row-gap: 10px;
	}

	.Input {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
		position: relative;
		padding-top: 5px;
		padding-bottom: 5px;
		height: 70px;
		background: #ffffff;
		border: 1px solid #d8d8d8;
		border-radius: 20px;
	}

	.Output {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
		position: relative;
		padding-top: 5px;
		padding-bottom: 5px;
		height: 70px;
		background: #ffffff;
		border: 1px solid #d8d8d8;
		border-radius: 20px;
	}

	.Price {
		position: relative;
		font-family: "MuseoModerno Light";
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		text-align: center;
	}

	.Cartera {
		display: flex;
		position: relative;
		width: 100%;
		align-items: center;
		justify-content: stretch;
	}

	.cartera {
		display: flex;
		position: relative;
		width: 100%;
		justify-content: center;
		position: relative;
		padding-top: 15px;
		padding-bottom: 15px;
		border: 0;
		font-family: "MuseoModerno Light";
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 38px;
		color: #ffffff;
		background: #e6007e;
		border-radius: 20px;
		cursor: pointer;
	}

	.cartera:disabled {
		cursor: not-allowed;
		background: #ac5585;
	}

	.loadingIcon {
		position: relative;
		width: 30px;
		height: 30px;
		margin-left: -55px;
		margin-right: 25px;
	}

	.botonSwitch {
		position: relative;
		width: 60px;
		height: 60px;
		display: block;
		margin: 0 auto;
		z-index: 1;
		margin-top: -25px;
		margin-bottom: -25px;
	}

	.Selection {
		display: flex;
		position: relative;
		height: 60px;
		margin-right: 10px;
	}

	.selection {
		display: flex;
		padding: 0 10px;
		position: relative;
		background: #f0e400;
		border: 0;
		border-radius: 40px;
		font-family: "Nunito Sans";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		align-items: center;
		color: #000000;
	}

	.gwhfToken,
	.usdcToken {
		width: 45px;
	}

	.textarea,
	.textarea2 {
		display: flex;
		width: 100%;
		padding-right: 25px;
		padding-left: 25px;
		position: relative;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 300;
		font-size: 40px;
		line-height: 76px;
		align-items: center;
		justify-content: center;
		border: 0;
		color: #262626;
		outline: none;
		background-color: #ffffff;
		border-radius: 20px;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.textarea,
	.textarea2:enabled {
		border: 0;
	}

	.Pet {
		display: flex;
		width: 297px;
		position: relative;
		justify-content: flex-end;
		margin-top: -275px;
		margin-left: calc(100% - 297px + 75px);
	}

	.MenuMovilApp {
		display: none;
	}

	.WithdrawNotice {
		display: flex;
		align-items: center;
		background-color: #e6007e;
		padding: 5px;
		padding-left: 25px;
		padding-right: 25px;
		border-radius: 15px;
		column-gap: 15px;
		margin-right: 200px;
	}

	.WithdrawText {
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 400;
		color: #ffffff;
	}

	.WithdrawBoolText {
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #ffffff;
		padding: 5px;
		border-radius: 15px;
	}

	.InfoIcon {
		width: 25px;
	}

	.WithdrawStateMessage {
		display: flex;
		position: absolute;
		z-index: 2;
		font-family: "MuseoModerno";
		font-style: normal;
		font-weight: 600;
		color: #415856;
		background-color: #f0e400;
		border: 2px solid #ffffff;
		text-align: center;
		border-radius: 15px;
		padding: 10px;
		width: 425px;
		margin: 50px 0 0 -200px;
	}

	.Dashboard {
		display: flex;
		padding: 50px;
		height: 100%;
		justify-content: center;
	}

	.growi-dashboard {
		display: flex;
		flex-direction: column;
		padding: 75px;
		padding-top: 25px;
		padding-bottom: 0px;
		row-gap: 25px;
		width: 100%;
		max-width: 1200px;
		background: linear-gradient(
			294.61deg,
			#313645 14.41%,
			#4d5757 34.9%,
			#6b7b6b 57.57%,
			#526f62 91.87%
		);
		border-radius: 25px;
	}
}

.divInvest {
	display: flex;
	position: relative;
	width: 220px;
	height: 52px;
	column-gap: 75px;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	user-select: none;
}

.investDashboard {
	position: relative;
	height: 43px;
	font-family: "MuseoModerno";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 50px;
	letter-spacing: -1px;
	color: #ffffff;
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.dashboardTextContents:hover {
	color: #f0e400;
	cursor: pointer;
}

.investTextDashboard:hover {
	color: #f0e400;
	cursor: pointer;
}

.investButton {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dashboardButton {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.metrics-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	align-content: center;
	flex-direction: row;
	column-gap: 15px;
	row-gap: 15px;
	width: 100%;
}

.metric-card {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	padding: 15px;
	font-family: "MuseoModerno Light";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 10px;
	color: #ffffff;
	background: #e6007e;
	border-radius: 20px;
	user-select: none;
}

.graficoToken {
	display: flex;
	width: 100%;
	height: 650px;
	align-items: center;
	justify-content: center;
	align-items: center;
}

.dataDiv {
	display: flex;
	align-items: center;
	justify-items: center;
	flex-direction: row;
}

canvas {
	width: 100% !important;
	height: 100% !important;
	background-color: #ffffff;
	padding: 25px;
	border-radius: 20px;
}
